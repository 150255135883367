import React, {useContext} from "react";
import "./Construction.scss";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";
import StyleContext from "../../contexts/StyleContext";

export default function Construction() {
    const {isDark} = useContext(StyleContext);
    return (
        <Fade bottom duration={1000} distance="5px">
            <div className="construction-div">
                <p className={isDark ? "dark-mode construction-text" : "construction-text"}>
                    {emoji("🚧This portfolio is under construction for future updates.🚧")}
                </p>
            </div>
        </Fade>
    );
}
